import { X } from "phosphor-react";
import { useEffect, useState } from "react";
import theme from "../../globalStyle/theme";
import {
    ExitIconWrapper,
    HeaderWrapper,
    HelpIconWrapper,
    ModalWrapper,
    SvgIcon,
    Txt
} from "./styled";

const EvaluateModal = () => {
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setShowHelp(false);
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (
                showHelp &&
                event.target instanceof HTMLElement &&
                !document
                    .getElementById("EvaluateModal")
                    ?.contains(event.target)
            ) {
                setShowHelp(false);
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [showHelp]);

    return (
        <>
            <ModalWrapper showHelp={showHelp}>
                <HeaderWrapper>
                    <Txt big>Pesquisa sobre a usabilidade</Txt>
                    <ExitIconWrapper>
                        <X
                            color="white"
                            weight="bold"
                            width={20}
                            height={20}
                            onClick={() => setShowHelp(false)}
                        />
                    </ExitIconWrapper>
                </HeaderWrapper>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeAqsXCdN335itXDrgyRg0J71Do_Ia-zDKksYXqf1oOqHD2Rg/viewform?embedded=true"
                    width="380px"
                    height="100%"
                >
                    Carregando…
                </iframe>
            </ModalWrapper>

            <HelpIconWrapper
                clicked={showHelp}
                onClick={() => setShowHelp(!showHelp)}
            >
                <SvgIcon height={32} width={32} fill={theme.colors.white} />
            </HelpIconWrapper>
        </>
    );
};

export default EvaluateModal;
