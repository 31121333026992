import { X } from "@phosphor-icons/react";
import emailjs from "emailjs-com";
import jwtDecode from "jwt-decode";
import { Warning } from "phosphor-react";
import { useEffect, useState } from "react";
import theme from "../../globalStyle/theme";
import { HotToastError, HotToastSucess } from "../HotToastFuncs";
import { InputLoader } from "../InputLoader";
import {
    ExitIconWrapper,
    HeaderWrapper,
    HelpIconWrapper,
    ModalWrapper,
    SendButton,
    TextArea,
    Txt
} from "./styled";

type Anexo = {
    name: string;
    file: string;
};

const HelpModal = () => {
    const [showHelp, setShowHelp] = useState(false);
    const [anexos, setAnexos] = useState<Anexo[]>([]);
    const [text, setText] = useState("");

    useEffect(() => {
        if (!showHelp) {
            setText("");
            setAnexos([]);
        }
    }, [showHelp]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setShowHelp(false);
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

     /* useEffect(() => {
         const handleClick = (event: MouseEvent) => {
             if (
                 showHelp &&
                 event.target instanceof HTMLElement &&
                 !document
                     .getElementById("HelpModal")
                     ?.contains(event.target)
             ) {
                 setShowHelp(false);
             }
         };
 
         document.addEventListener("click", handleClick);
 
         return () => {
             document.removeEventListener("click", handleClick);
         };
     }, [showHelp]); */

    return (
        <>
            <InputLoader
                id="LoadInput"
                OnExecute={async (event) => {
                    function file_to_base64(file: File) {
                        return new Promise<string>((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                                if (event.target) {
                                    resolve(event.target.result as string);
                                } else {
                                    reject("Erro ao carregar arquivo");
                                }
                            };
                            reader.readAsDataURL(file);
                        });
                    }
                    if (event.target.files) {
                        const files = Array.from(event.target.files) as any[];
                        const anx = [...anexos];
                        for (const file of files) {
                            const fName = file.name.split(".")[0];
                            const base64 = await file_to_base64(file);
                            anx.push({
                                name: fName,
                                file: base64
                            });
                        }
                        setAnexos(anx);
                    } else {
                        HotToastError("Erro ao carregar arquivo");
                    }
                }}
            />
            <ModalWrapper showHelp={showHelp}>
                <HeaderWrapper>
                    <Txt big>Relate um Problema</Txt>
                    <ExitIconWrapper>
                        <X
                            color="white"
                            weight="bold"
                            width={20}
                            height={20}
                            onClick={() => setShowHelp(false)}
                        />
                    </ExitIconWrapper>
                </HeaderWrapper>
                <Txt small>
                    {
                        "Se você está enfrentando algum problema, por favor relate (de maneira detalhada) para que possamos consertar o mais rápido possível."
                    }
                </Txt>
                <TextArea
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                    placeholder="Descreva o problema..."
                />

                {/* <AnexosHeader>
                    <Txt>
                        Anexos <Txt small>(opcional)</Txt>
                    </Txt>
                    <AnexoWrapper add htmlFor="LoadInput">
                        <File size={28} color={theme.colors["gray/500"]} />
                        <Txt small>Adicionar Anexo</Txt>
                    </AnexoWrapper>
                </AnexosHeader> */}
                {/* <AnexosWrapper>
                    {anexos.map((anexo, index) => (
                        <AnexoWrapper
                            onClick={() => {
                                const newAnexos = [...anexos];
                                newAnexos.splice(index, 1);
                                setAnexos(newAnexos);
                            }}
                            key={index}
                        >
                            <File size={14} color={theme.colors["gray/500"]} />
                            <Txt small>{anexo.name}</Txt>
                        </AnexoWrapper>
                    ))}
                </AnexosWrapper> */}
                <SendButton
                    onClick={() => {
                        const tokenString = localStorage.getItem("token")!;
                        const token: any = jwtDecode(tokenString);
                        //console.log(token);
                        const templateParams = {
                            message: text,
                            user: token["Jvris.User.Name"],
                            id: token["Jvris.User.Id"],
                            imgs: "sem Serviço de Anexos"
                        };
                        emailjs.send(
                            "service_k33a75i",
                            "template_o70vyh4",
                            templateParams,
                            "6nlRcbr6dhNi-t3Xa"
                        ).then(
                            function (response) {
                                HotToastSucess("Mensagem Enviada com Sucesso");
                                setShowHelp(false);
                            },
                            function (error) {
                                HotToastError("Erro ao Enviar Mensagem");
                            }
                        );
                    }}
                >
                    <Txt color="white">Enviar</Txt>
                </SendButton>
            </ModalWrapper>
            <HelpIconWrapper
                clicked={showHelp}
                onClick={() => setShowHelp(!showHelp)}
            >
                <Warning size={32} color={theme.colors.white} />
            </HelpIconWrapper>
        </>
    );
};

export default HelpModal;
