import styled from "styled-components";

export const Wrapper = styled.div`
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 99999;
`;

export const TextArea = styled.textarea`
    width: 100%;
    height: 80%;
    min-height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    font-size: 1.4rem;

    &:focus {
        outline: 1px solid ${({ theme }) => theme.colors.jvrisAqua};
    }
`;

export const SendButton = styled.div`
    align-self: flex-end;
    background-color: ${({ theme }) => theme.colors.jvrisAqua};
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.jvrisAquaDark};
    }
`;

export const AnexosHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const AnexoWrapper = styled.label<{
    add?: boolean;
}>`
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    border: 1px ${({ add }) => (add ? "solid" : "dashed")} #777777;
    border-radius: 5px;
    padding: 4px;
    ${({ add }) => add && "padding-right: 8px;"}
    cursor: pointer;
    user-select: none;

    transition: 0.2s;

    &:hover {
        background-color: ${({ add }) => (add ? "#f4f4f4" : "#fddcdc")};
    }
`;

export const AnexosWrapper = styled.div`
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: '40%';
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
    //align-items: center;
    padding: 10px;
    gap: 10px;
`;

export const ExitIconWrapper = styled.div`
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors["gray/600"]};

    transition: 0.2s;

    &:hover {
        background-color: ${({ theme }) => theme.colors["gray/700"]};
    }
`;

export const Txt = styled.p<{
    small?: boolean;
    big?: boolean;
    color?: string;
}>`
    font-size: ${({ small, big }) =>
        small ? "1.3rem" : big ? "1.9rem" : "1.7rem"};
    font-weight: ${({ small, big }) => (small ? "400" : big ? "700" : "600")};
    color: ${({ theme, small }) =>
        small ? theme.colors["gray/500"] : theme.colors["gray/700"]};
    ${({ color }) => color && `color: ${color};`}
    margin-bottom: 0;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalWrapper = styled.div<{
    showHelp: boolean;
}>`
    display: ${({ showHelp }) => (showHelp ? "flex" : "none")};
    position: absolute;
    right: auto;
    top: calc(100% - (74px + 60vh));
    left: 5px;
    width: 400px;
    height: 60vh;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    list-style: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors["gray/400"]};
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
`;

export const HelpIconWrapper = styled.div<{
    clicked?: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.darkGrey};

    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    user-select: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        scale: 1.1;
    }
`;

export const SvgIcon = ({
    fill,
    width,
    height
}: {
    fill: string;
    width: number;
    height: number;
}) => (
    <svg
        width="240"
        height="240"
        viewBox="0 0 240 240"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        style={{
            width: width,
            height: height,
            userSelect: "none"
        }}
    >
        <path
            style={{
                userSelect: "none"
            }}
            d="M110.7 128.5C118.4 114.6 133.2 106.4 141.8 94.1C150.9 81.2 145.8 57.1 120 57.1C103.1 57.1 94.7999 69.9 91.2999 80.5L65.3999 69.6C72.4999 48.3 91.7999 30 119.9 30C143.4 30 159.5 40.7 167.7 54.1C174.7 65.6 178.8 87.1 168 103.1C156 120.8 144.5 126.2 138.3 137.6C135.8 142.2 134.8 145.2 134.8 160H105.9C105.8 152.2 104.6 139.5 110.7 128.5ZM140 200C140 211 131 220 120 220C109 220 99.9999 211 99.9999 200C99.9999 189 109 180 120 180C131 180 140 189 140 200Z"
            //fill="black"
        />
    </svg>
);
