import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomSelect = styled(Select)`
  width: 32rem;
  * {
    font-size: 1.3rem;
  }
`;
