import styled from "styled-components";

export const Wrapper = styled.div`
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 99999;
`;

export const TextArea = styled.textarea`
    width: 100%;
    height: 80%;
    min-height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    font-size: 1.4rem;

    &:focus {
        outline: 1px solid ${({ theme }) => theme.colors.jvrisAqua};
    }
`;

export const SendButton = styled.div`
    align-self: flex-end;
    background-color: ${({ theme }) => theme.colors.jvrisAqua};
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.jvrisAquaDark};
    }
`;

export const AnexosHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const AnexoWrapper = styled.label<{
    add?: boolean;
}>`
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    border: 1px ${({ add }) => (add ? "solid" : "dashed")} #777777;
    border-radius: 5px;
    padding: 4px;
    ${({ add }) => add && "padding-right: 8px;"}
    cursor: pointer;
    user-select: none;

    transition: 0.2s;

    &:hover {
        background-color: ${({ add }) => (add ? "#f4f4f4" : "#fddcdc")};
    }
`;

export const AnexosWrapper = styled.div`
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: "40%";
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
    //align-items: center;
    padding: 10px;
    gap: 10px;
`;

export const ExitIconWrapper = styled.div`
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors["gray/600"]};

    transition: 0.2s;

    &:hover {
        background-color: ${({ theme }) => theme.colors["gray/700"]};
    }
`;

export const Txt = styled.p<{
    small?: boolean;
    big?: boolean;
    color?: string;
}>`
    font-size: ${({ small, big }) =>
        small ? "1.3rem" : big ? "1.9rem" : "1.7rem"};
    font-weight: ${({ small, big }) => (small ? "400" : big ? "700" : "600")};
    color: ${({ theme, small }) =>
        small ? theme.colors["gray/500"] : theme.colors["gray/700"]};
    ${({ color }) => color && `color: ${color};`}
    margin-bottom: 0;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalWrapper = styled.div<{
    showHelp: boolean;
}>`
    display: ${({ showHelp }) => (showHelp ? "flex" : "none")};
    position: absolute;
    right: auto;
    top: calc(100% - (74px + 60vh));
    left: 5px;
    width: 400px;
    height: 60vh;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    list-style: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors["gray/400"]};
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const HelpIconWrapper = styled.div<{
    clicked?: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.darkGrey};

    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    user-select: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        scale: 1.1;
    }
`;

export const SvgIcon = ({
    fill,
    width,
    height
}: {
    fill: string;
    width: number;
    height: number;
}) => (
    <svg
        width="1024"
        height="1024"
        viewBox="0 0 1024 1024"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        style={{
            width: width,
            height: height,
            userSelect: "none"
        }}
    >
        <path
            style={{
                userSelect: "none"
            }}
            d="M704 192H864V928H160V192H320V256H704V192ZM288 512H736V448H288V512ZM288 768H736V704H288V768ZM384 192V96H640V192H384Z"
            //fill="black"
        />
    </svg>
);
