const theme = {
  colors: {
    white: "#fff",
    black: "#080808",
    lighterGrey: "#F1F2F7",
    MenuTextColor: "#b8b2a8",
    menuTextColorHover: "#55e2da",
    hiperLinkColor: "#0000FF",
    menuHover: "#28282e",
    sectionLink: "#202324",
    jvrisAqua: "#1fb5ad",
    jvrisAquaDark: "#1C6E6A",
    jvrisAquaDarker: "#103937",
    blueTag: "#295d94",
    darkBlue: "#1d2b36",
    darkerBlue: "#202f3c",
    lightGrey: "#eaeaec",
    grey: "#bebebe",
    darkgrey_2: "#9e9e9e",
    lighterGray: "#dddddd",
    lightGray: "#f8f8f8",
    gray: "#767676",
    bgTitlePage: "#e0e1e7",
    titlePage: "#222227",
    midnightBlue: "#32323A",
    darkGrey: "#28282e",
    darkerGrey: "#202025",
    softRed: "#D9534F",
    darkRed: "#c32e2a",
    darkerRed: "#a92825",
    saveGreenButtonNormal: "#95b75d",
    saveGreenButtonHover: "#88a755",
    saveGreenButtonActive: "#398439",
    softBlue: "#53BEE6",
    blue: "#3fafd8",
    softGreen: "#5CB85C",
    softGreenDark: "#449D44",
    softYellow: "#F0AD4E",
    softYellowDark: "#ee9f31",
    softYellowLight: "#F2BF77",
    softOrange: "#C66422",
    softPurple: "#8189d0",
    mediumGrey: "#AEB2B7",
    bgCleanButton: "#53bee6",
    bgCleanButtonLight: "#57c8f1;",
    pageButton: "#fffbf4ea",
    pageButtonDark: "#fffbf4a3",
    "gray/50": "#f9fafb",
    "gray/100": "#f3f4f6",
    "gray/200": "#e5e7eb",
    "gray/300": "#d1d5db",
    "gray/400": "#9ca3af",
    "gray/500": "#6b7280",
    "gray/600": "#4b5563",
    "gray/700": "#374151",
    "gray/800": "#1e293b",
    "gray/900": "#111827",
  },
};

export default theme;
