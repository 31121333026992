import theme from "../../../globalStyle/theme";

export const UrgenciesOptions = {
  Normal: "Normal",
  Urgente: "Urgente",
  Importante: "Importante",
  Valor_expressivo1: "Valor_expressivo",
  Valor_expressivo2: "Valor Expressivo" ,
  Sustentacao_oral: "Sustentacao_oral",
};
export const UrgenciesColors = {
  Normal: theme.colors.softGreen,
  Urgente: theme.colors.softOrange,
  Importante: theme.colors.softRed,
  Valor_expressivo: theme.colors.softYellow,
  Sustentacao_oral: theme.colors.softPurple
};

export const cadastroOptions = {
  automaticamente: "automatico",
  manualmente: "manual",
};

export const PecaCadastrada = {
  cadastrada: "Cadastrada",
  naoCadastrada: "Não Cadastrada",
};

export const sigiloOptions = {
  0: "Público",
  1: "Segredo",
  2: "Mínimo",
  3: "Médio",
  4: "Intenso",
  5: "Absoluto",
};
