import { OptionProps } from "./option.interface";
import * as S from "./styled";
import Icon from "../Icon";

export const Option = ({
  data,
  handleSelectedOption,
  selectedOption,
  isSideBarOpen,
  setSideBarOpen,
}: OptionProps) => {
  return (
    <S.SubBarOptionsContainer>
      <S.OptionSideBarSubBar
        isSideBarOpen={isSideBarOpen}
        open={Boolean(
          data.listMenuFilhos.find(
            (m) => m.id === +selectedOption || data.id === selectedOption
          )
        )}
        onClick={() => {
          handleSelectedOption(data.id);
          setSideBarOpen(true);
        }}
      >
        <Icon menuId={data.id} />
        <S.TextMenu isSideBarOpen={isSideBarOpen}>{data.txMenu}</S.TextMenu>
        <S.ArrowRightIcon
          isSideBarOpen={isSideBarOpen}
          open={Boolean(
            data.listMenuFilhos.find((m) => m.id === +selectedOption) ||
              data.id === selectedOption
          )}
        />
      </S.OptionSideBarSubBar>
      <S.SubOptionsContainer
        className="SubOptionsContainer"
        id="SubOptionsContainer"
        open={
          isSideBarOpen &&
          Boolean(
            data.listMenuFilhos.find((m) => m.id === +selectedOption) ||
              data.id === selectedOption
          )
        }
      >
        {data.listMenuFilhos?.map((sl) => (
          <S.OptionSubBarDirectLink
            key={sl.id}
            to={sl.txPagina}
            onClick={() => handleSelectedOption(sl.id)}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            {sl.txMenu}
          </S.OptionSubBarDirectLink>
        ))}
      </S.SubOptionsContainer>
    </S.SubBarOptionsContainer>
  );
};
