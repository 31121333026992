import styled from "styled-components";

export const StatusContainer = styled.div`
  padding: 10px;
  max-height: 40px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
