export enum PROFILES {
  ANALISTA = 1,
  PROCURADOR = 2,
  ASSESSOR_PROCURADOR = 3,
  CONTADOR_CHEFE = 4,
  OPERADOR = 5,
  CONTADOR = 6,
  OPERADOR_ASSESSOR_DO_PROCURADOR = 7,
  ASSESSOR_RPV = 8,
  OPERADOR_CONSULTA = 9,
  ASSESSOR_DE_PROCURADOR = 10,
}
