import * as S from "./styled";
import { PageTitleProps } from "./interfaces/title-page.interface";
import { getVerboseCurrentDate } from "../../utils/getVerboseCurrentDate.util";

export const PageTitle = (props: PageTitleProps) => {
  return (
    <S.PageTitleContainer>
      {props.pageIcon ? props.pageIcon : <S.PageIcon weight="bold" />}
      <S.PageTitle>{props.pageTitle} { props.showCurrentDate && ` - ${getVerboseCurrentDate()}` }</S.PageTitle>
      <S.ContainerButton>{props.button}</S.ContainerButton>
    </S.PageTitleContainer>
  );
};
