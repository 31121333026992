import { useState } from "react";
import { Outlet } from "react-router-dom";
import EvaluateModal from "../EvaluateModal";
import { Header } from "../Header";
import HelpModal from "../HelpModal";
import { SideBar } from "../SideBar";
import * as S from "./styled";

const Overlay = () => {
    const [showSideMenu, setShowSideMenu] = useState(true);
    return (
        <S.Container>
            <Header
                ToogleSideMenu={() => setShowSideMenu(!showSideMenu)}
                SideMenuIsOpen={showSideMenu}
            />
            <S.DashboardContainer>
                <SideBar
                    isSideBarOpen={showSideMenu}
                    setSideBarOpen={setShowSideMenu}
                />
                <S.DashboardContent>
                    <Outlet />
                </S.DashboardContent>
                <S.ModalsWrapper>
                    <HelpModal />
                    {/* Aguardando liberação de pesquisa */}
                    <EvaluateModal />
                </S.ModalsWrapper>
            </S.DashboardContainer>
        </S.Container>
    );
};

export default Overlay;
